import React from 'react';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import Image from '../components/atoms/Image';
import { Link } from 'gatsby';
import TwoColumn from '../components/organisms/TwoColumn';

const Holder = styled.div`
  p { ${props => props.theme.fluidType( 1 )}; }
`;

function RecyclePage() {

  return (
    <>
      <Seo title="Recycle" description="Unicorns Forever is developing a recycling program to combat fast growing young plants."/>
      <Holder>

        <TwoColumn>
          <Image imgName="recycle-00003.jpg"/>
          <div>
            <h1>The Unicorns Forever recycling program</h1>
            <p>If you do all the right things as a parent, ie. water your kids, plant them in a
              sunny spot, give them good soil and fertilise them once in a while then they should
              grow. And while growing is good, it can prove to be expensive. You buy clothes on a
              Monday and they have grown out of them on Friday. A pain. An expensive pain. SO!
              Unicorns Forever is developing a recycling program to combat this awkward fact of
              life.</p>
          </div>
        </TwoColumn>
        <TwoColumn>
          <div>
            <p>You can return any product we have sold you and we will give you a voucher of $10 for
              our online store. This means the next size up of your kids favourite brand will be
              cheaper. It also means we can sell your old shirt on our second-hand market. This gives you and
              other parents the opportunity to purchase second-hand goods at much lower prices. It
              also extends the life of the product, which is definitely good for us and probably
              good for the environment! A win for you, a win for us and a possible win for the
              environment!</p>
          </div>
          <Image imgName="recycle-00002.jpg"/>
        </TwoColumn>
        <TwoColumn>
          <Image imgName="recycle-00001.jpg"/>
          <div>
            <p>The second-hand market is not up and running right now as we haven’t got any
              products yet and we are yet to think through all the details. If you are interested
              you can sign up to our newsletter and we will notify you when it is up and running.</p>
            <p>The only products we don’t accept are the colouring books, for obvious reasons.</p>
            <p>Just send your old products to 4/1 Aitken Ave, Queenscliff, NSW 2096. Make sure you
              include your name and email so we can send your voucher through.</p>
            <p>Alternatively you can find us at a market stall around Sydney. Keep an eye on the <Link to="/updates">updates page</Link> for upcoming market dates</p>
          </div>
        </TwoColumn>
      </Holder>
    </>
  )
}

export default RecyclePage;

